import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const get = (courseId, teachId) => {
  return ajax.get(`${base}/api/itedu/v1/teach-lecture-note/get?c=${courseId}&t=${teachId}`);
};

export const teachLectureNoteApi = {
  // import时得花括号明确
  get: get,
};
