<template>
  <div class="talk">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        讲义下载
      </div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="item" v-for="idx in index" :key="idx">
        <div class="sys">
          <div class="img">
            <img :src="talkLeftImg" />
          </div>
          <!-- <div class="text" v-html="teach.content[idx].content"></div> -->
          <div class="text">
            <a :href="noteList[idx].url"> {{ noteList[idx].fileName }} </a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="left"></div>
      <div class="center">
        <div class="btn">
          <img :src="talkContinueImg" />
        </div>
      </div>
      <div class="right" @click="onClickShowCsWidget">
        <div class="up">
          <img :src="talkTeacherImg" />
        </div>
        <div class="down">
          联系老师
        </div>
      </div>
    </div>

    <csWidget v-show="showCsWidget" :csId="'1'"></csWidget>
    <videoWidget :courseId="courseId" :scheduleId="scheduleId" :teachId="teachId" v-if="showVideo"></videoWidget>
  </div>
</template>

<script>
const COS = 0;
const MARKDOWN = 1;
const HTML = 2;
const VOD = 3;
const PRACTICE = 4;

import { TouchEmulator } from "@/util/touch.js";
import { ImagePreview } from "vant";
import csWidget from "@/components/csWidget.vue";
import videoWidget from "@/components/videoWidget.vue";
import simpleMM from "@/components/simpleMM.vue";
import { teachLectureNoteApi } from "@/api/teachLectureNoteApi.js";
export default {
  components: { csWidget, videoWidget },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      playVideoImg: require("@/assets/images/video-play.png"),
      talkTeacherImg: require("@/assets/images/talk-teacher.png"),
      talkContinueImg: require("@/assets/images/talk-continue.png"),
      talkLeftImg: require("@/assets/images/talk-left.png"),
      talkRightImg: require("@/assets/images/talk-right.png"),
      courseId: "0",
      teachId: "0",
      index: [],
      noteList: [
        {
          fileName: "",
          url: "",
        },
      ],
      showCsWidget: false,
      showVideo: false,
    };
  },
  methods: {
    getParamId() {
      if (this.$route.query) {
        if (this.$route.query.c) {
          this.courseId = this.$route.query.c;
        }
        if (this.$route.query.t) {
          this.teachId = this.$route.query.t;
        }
      }
    },
    getTeachLectureNote() {
      if (parseInt(this.courseId) <= 0 || parseInt(this.teachId) <= 0) {
        return;
      }
      teachLectureNoteApi.get(this.courseId, this.teachId).then((ret) => {
        console.log("teach lecture note.vue", ret);
        if (ret.code == 0 && ret.datas) {
          this.noteList = [];
          for (let i = 0; i < ret.datas.length; i++) {
            this.index.push(i);
            this.noteList.push({
              fileName: ret.datas[i].fileName,
              url: ret.datas[i].url,
            });
          }
          console.log("note list", this.noteList);
        }
      });
    },
    nextTalk() {
      if (this.index.length >= this.teach.content.length) {
        return;
      }
      const len = this.index.length;
      this.index.push(len);
      this.$nextTick(() => {
        let container = this.$el.querySelector(".main");
        container.scrollTop = container.scrollHeight + 400;
        this.listenOnImageClick();
      });
    },
    listenOnImageClick() {
      const imgList = document.querySelectorAll(".md img");
      for (const tmp of imgList) {
        tmp.addEventListener("click", this.imgClick);
      }
    },
    imgClick(e) {
      //console.log(e);
      const elem = e.srcElement;
      ImagePreview({ images: [elem.currentSrc], closeable: true });
    },
    onGoBack() {
      this.$router.go(-1);
    },
    onClickShowCsWidget() {
      this.showCsWidget = true;
    },
    onClickVideo() {
      if (this.vodParam == null) {
        return;
      }
      this.showVideo = true;
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
    listenOnCloseVideoWidget() {
      this.$EventBus.$on("onCloseVideoWidget", (val) => {
        this.showVideo = false;
      });
    },
  },
  mounted() {
    this.getParamId();
    this.getTeachLectureNote();

    this.listenOnCloseCsWidget();
    this.listenOnCloseVideoWidget();
  },
  watch: {},
};
</script>

<style lang="less">
.md {
  .md-pre {
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    border-radius: 4px;
    background-color: #dddddd;
    padding: 5px;
    margin: 5px 0px;
    box-shadow: 2px 2px 4px #aaaaaa;
  }
  .md-p {
    margin: 0rem 0rem 1.2rem 0rem;
    &:last-child {
      margin: 0rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .md-ul {
    display: block;
    margin: 0 0 15px 0;
    .md-li {
      margin-left: 15px;
      display: block;
      &::before {
        content: "◦";
        margin: 0 10px 0 0px;
        font-size: 1.5rem;
      }
      .md-ul {
        .md-li {
          margin-left: 15px;
          display: block;
          &::before {
            content: "-";
            margin: 0 10px 0 0px;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.talk {
  display: flex;
  flex-direction: column;
  background: rgb(241, 241, 241);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    .item {
      .sys {
        display: flex;
        flex-direction: row;
        margin: 0 0 28px 12px;
        .img {
          margin-top: 15px;
          img {
            width: 6px;
            height: auto;
          }
        }
        .text {
          background: #ffffff;
          border-radius: 6px;
          font-size: 16px;
          color: #323233;
          letter-spacing: 0.36px;

          max-width: 80%;
          width: fit-content;
          padding: 9px 12px;
        }
      }
      .user {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0 12px 28px 0px;
        .img {
          margin-top: 15px;
          img {
            width: 6px;
            height: auto;
          }
        }
        .text {
          background: #d4daf7;
          border-radius: 6px;
          font-size: 16px;
          color: #000000;
          letter-spacing: 0.36px;

          max-width: 80%;
          width: fit-content;
          padding: 9px 12px;
        }
      }
    }
  }
  .footer {
    flex: 0 0 auto;
    height: 78px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #f7f7f7;
    border-top: 1px solid #d2d2d2;
    //padding: 20px 0 0 0;
    .left {
      margin: 15px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      width: 86px;
      .up {
        img {
          width: 24px;
          height: auto;
        }
      }
      .down {
        font-size: 10px;
        color: #323233;
        letter-spacing: 0.22px;
      }
    }
    .center {
      margin: 15px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 40px;
        background: #4d52d1;
        border-radius: 12px;
        img {
          width: 21px;
          height: auto;
        }
      }
    }
    .right {
      margin: 15px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 86px;
      .up {
        img {
          width: 24px;
          height: auto;
        }
      }
      .down {
        font-size: 10px;
        color: #323233;
        letter-spacing: 0.22px;
      }
    }
  }
}
</style>
